import { get } from 'lodash';
import React from 'react';
import SafeHtmlElements from '../SafeHtmlElements/SafeHtmlElements';
import commaFunction from '../../../utility/addCommaToNumbers/commaFunction';

const generateSafeCell = (
  key,
  placeHolder = '--',
  precision = 1,
  addCommas = false,
  fixedTrailingPoints = null,
  handleClick = () => {},
  className = null,
) =>
  function DataTableSafeCell(rowData) {
    let data = get(rowData, key, null);
    if (addCommas) {
      data = commaFunction(data, precision);
    }
    return (
      <SafeHtmlElements
        tagName="span"
        precision={precision}
        placeholder={placeHolder}
        dataTestId={key}
        fixedTrailingPoints={fixedTrailingPoints}
        onClick={() => handleClick(data)}
        className={className}
      >
        {data}
      </SafeHtmlElements>
    );
  };

export default generateSafeCell;
