export const COMPASS_BACKEND_FORMAT = 'YYYY-MM-DD';
export const UI_DISPLAY_DATE = 'MMM DD, YYYY';
export const SHORT_UI_DISPLAY_DATE = 'MMM YYYY';
export const MONTH_DATE_DISPLAY = 'MMM DD';
export const UI_DISPLAY_TIME = 'hh:mm';
export const DATE_TIME_FORMAT = 'MMM DD, YYYY hh:mm A';
export const YEAR_DISPLAY = 'YYYY';
export const MONTH_DISPLAY = 'MM';
export const SHORT_MONTH_DISPLAY = 'MMM';
export const DATE_DISPLAY = 'DD';
