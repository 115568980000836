import axios from 'axios';
import { generateHttpActions } from '../../../../../utility';

function sendChatMessageHttpRequest(content, trialId, conversationId = null) {
  const functionName = 'aiMessage';

  const httpActions = generateHttpActions(functionName);
  const resource = `genai-assistant/conversation/messages`;

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const payload = {
        content,
        metadata: {
          trial_id: trialId,
        },
      };

      if (conversationId) {
        payload.conversation_id = conversationId;
      }

      const response = await axios.post(resource, payload);
      dispatch(httpActions.success(response.data));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default sendChatMessageHttpRequest;
