import axios from 'axios';
import { generateHttpActions } from '../../../../../utility';

function getPromptLibraryHttpRequest() {
  const functionName = 'promptLibrary';

  const httpActions = generateHttpActions(functionName);
  const resource = `genai-assistant/prompt-library`;

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const response = await axios.get(resource, {});
      dispatch(httpActions.success(response));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default getPromptLibraryHttpRequest;
